import React from 'react';
import style from './AdminCard.module.css';

const AdminCard = () => {

    const logOut = () => {
        const url = 'http://82.146.60.174:8181/api/auth/logout';
        const token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: token
            },
        };
        fetch(url, requestOptions)
            .then(async response => { 
                if (response.ok) {
                    localStorage.removeItem('token');
                    window.location = ('/auth')
                }
                // await response.json();
            })
            .catch(error => {
                this.setState({ errorMessage: error.toString() });
                console.error('Ошибка:', error.errorData.message);
            });
    }

    return (
        <div className={style.AdminCard}>
            <div className={style.AdminCard__name}>
                <div className={style.AdminCard__icon}>
                    <div>A</div>
                </div>
                <div className={style.AdminCard__login}>
                    <p>admin@example.com</p>
                </div>
            </div>
            <button onClick={logOut}>
                Выйти
            </button>
        </div>
    )

}

export default AdminCard
import React, {Component} from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomMarker from '../CustomMarker/CustomMarker';
import CustomPopup from '../CustomPopup/CustomPopup';

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoidmV0ZG9jcyIsImEiOiJja3V3cXM0bHkyc2ZyMndxcm85N2g0MGQ5In0.bHDD5XcguAZZEAoqadeCnA'
});

class CustomMap extends Component {

    state = {
        markers: [],
        selectedIndex: null
    };
    mapContainer = React.createRef();
    
    setSelectedMarker = (index) => {
        this.setState({ selectedIndex: index })
    }
    
    closePopup = () => {
        this.setSelectedMarker(null)
    };
    
    openPopup = (index) => {
        this.setSelectedMarker(index)
    }

    initMap = (map) => {
        map.setCenter([37.6153107, 55.7520263])
        map.setZoom([12]);
        this.setBounds(map);
    }

    setBounds = ((map) => {
        const bounds = map.getBounds();
        this.getGeolocations(bounds);
    })

    getGeolocations = (bounds) => {
        const minLat = bounds._sw.lat;
        const maxLat = bounds._ne.lat;
        const minLon = bounds._sw.lng;
        const maxLon = bounds._ne.lng;

        const url = `http://82.146.60.174:8181/api/geolocations?minLat=${minLat}&maxLat=${maxLat}&minLon=${minLon}&maxLon=${maxLon}`;
        const token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Authorization': token,
                'accept': 'application/json'
            }
        };

        fetch(url, requestOptions) 
        .then(async response => {
            const json = await response.json();
            if (response.ok) {
                this.setState({markers: json.data})
            } else {
                const error = json.error.errorData[0].message
                console.log('Ошибка:', error);
            }
        })
        .catch(error => {
            console.error('Ошибка:', error.message);
        });
    }

    componentWillUnmount() {
        if(this.map) {
            this.map.remove();
        }
    }

    render() {

        const {markers, selectedIndex} = this.state;

        return (
            <Map
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                    height: '100vh',
                    width: '100vw'
                }}
                ref={this.mapContainer}
                onMoveEnd={this.setBounds}
                onStyleLoad={this.initMap}
                onClick={this.closePopup}
            >

                {
                    markers.map((marker, index) => {
                        return(
                            <CustomMarker
                                key={`marker-${index}`}
                                index={index}
                                marker={marker}
                                openPopup={this.openPopup}
                                closePopup={this.closePopup}
                            />
                        )
                    })
                }
                {                
                    selectedIndex !== null ?
                        <CustomPopup 
                            marker={markers[selectedIndex]}
                        />
                    : null
                }
            </Map>
        );
    }
}

export default CustomMap

import React from 'react';
import { Marker } from 'react-mapbox-gl';
import style from './CustomMarker.module.css';

const CustomMarker = ({index, marker, openPopup}) => {
    return (
        <Marker 
            className={style.CustomMarker}
            coordinates={[marker.lon, marker.lat]}
        >
            <div onClick={() => openPopup(index)}>
                <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31 16C31 19.5723 29.6897 23.4537 27.4702 27.4337C25.2552 31.4056 22.1712 35.4107 18.7136 39.2115C17.2522 40.8181 14.7478 40.8181 13.2864 39.2115C9.82884 35.4107 6.74483 31.4056 4.52981 27.4337C2.31031 23.4537 1 19.5723 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z" fill="#E8F4FF" stroke="#9ECBFF" stroke-width="2"/>
                    <path d="M19.3334 13.6667V10.338C19.3334 8.49603 17.8394 7 16 7C14.1606 7 12.6667 8.49603 12.6667 10.338V13.6667H19.3334Z" fill="#8BC9FF"/>
                    <path d="M22.6666 13.6667H19.3333V20.3333H22.6666C24.506 20.3333 26 18.8373 26 16.9953C25.9906 15.1627 24.506 13.6667 22.6666 13.6667Z" fill="#8BC9FF"/>
                    <path d="M12.6667 13.6667H9.33333C7.49393 13.6667 6 15.1606 6 17C6 18.8394 7.49393 20.3333 9.33333 20.3333H12.6667V13.6667Z" fill="#8BC9FF"/>
                    <path d="M19.3334 20.3333H12.6667V23.662C12.6667 25.504 14.1606 27 16 27C17.8394 27 19.3334 25.504 19.3334 23.662V20.3333Z" fill="#8BC9FF"/>
                </svg>
            </div>
        </Marker>
    )
};

export default CustomMarker
import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import AdminPanel from './pages/AdminPanel/AdminPanel';
import Authorization from './pages/Authorization/Authorization';

import ProtectedRoute from './ProtectedRoute';

const App = () => {

  const isAuthorized = () => {
      const token = localStorage.getItem('token');
      return token ?  true : false
  }

  return (
    <div className="App">
      <Switch>
        <ProtectedRoute exact path="/" to="/auth"/>
        <Route path='/auth' component={Authorization} >
          {isAuthorized() ? <Redirect to="/admin" /> : null}
        </Route>
        <ProtectedRoute path="/admin" component={AdminPanel} />
      </Switch>
    </div>
  );
  
}

export default App;

import React, { Component } from 'react';
import { Popup } from 'react-mapbox-gl';
import style from './CustomPopup.module.css';
import user from '../../img/user.svg'

class CustomPopup extends Component {
     
    state = {
        avatar: true,
        avatarUrl: ''
    };

    componentDidMount() {
        this.getFile();
    }

    getFile = () => {
        const fileUrl = this.props.marker.accountInfo.avatar;
        const url = `http://82.146.60.174:8181/api/storage/download/${fileUrl}`;
        const token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Authorization': token,
                'accept': '*/*'
            }
        };
        console.log(fileUrl)

        fetch(url, requestOptions) 
        .then(async response => {
            const blob = await response.blob();
            if (response.ok) {
                const objectURL = URL.createObjectURL(blob)
                this.setState({avatarUrl: objectURL})
            } else {
                this.setState({avatar: false})
            }
        })
        .catch(error => {
            console.error('Ошибка:', error.message);
        });
    }

    render() {

        const {avatar, avatarUrl} = this.state;
        const {marker} =this.props;

        return (
            <>
                {
                    marker != null ? 
                        <Popup
                        className={style.CustomPopup}
                        coordinates={[marker.lon, marker.lat]}
                        offset={[250, 130]}
                    >
                        <div className={style.CustomPopup__content}>
                            <div className={style.CustomPopup__avatar}>
                                {
                                    avatar 
                                    ? <img src={avatarUrl} alt='Аватар'/>
                                    : <img src={user} alt='Аватар'/>
                                }
                            </div>
                            <div>
                                <div className={style.CustomPopup__title}>
                                    <h1>{marker.accountInfo.fullName}</h1>
                                </div>
                                <div className={style.CustomPopup__description}>
                                    <div className={style.CustomPopup__description_item}>
                                        <p>Специальность:</p>
                                        <p>{marker.accountInfo.specialization}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                    : null
                }
            </>
        )
    }
};

export default CustomPopup
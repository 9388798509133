import React, { Component } from 'react';
import AdminCard from '../../components/AdminCard/AdminCard';
import Map from '../../components/Map/Map';
import style from './AdminPanel.module.css';

class AdminPanel extends Component {

    render() {
        return (
            <div className={style.AdminPanel}>
                <AdminCard/>
                <Map/>
            </div>
        )

    }
}

export default AdminPanel
import React, { Component } from 'react';
import AuthorizationForm from '../../components/AuthorizationForm/AuthorizationForm';
import style from './Authorization.module.css';

class Authorization extends Component {

    render() {
        return (
            <div className={style.Authorization}>
                <AuthorizationForm/>
            </div>
        )
    }
}

export default Authorization